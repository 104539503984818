var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popupForm"},[_c('van-popup',{style:({ width: '90%', maxWidth: '495px' }),attrs:{"close-on-click-overlay":false,"round":"","closeable":"","close-icon":"close"},on:{"click-close-icon":_vm.close},model:{value:(_vm.popupShow),callback:function ($$v) {_vm.popupShow=$$v},expression:"popupShow"}},[_c('h2',[_vm._v("填写联系方式")]),_c('div',{staticClass:"form"},[_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"name":"legalPhone","label":"联系人手机号","placeholder":"联系人手机号（必填）","rules":[
            {
              required: true,
              message: '请输入正确的手机号',
              validator: _vm.checkPhone,
            },
          ]},on:{"input":_vm.getPhone},model:{value:(_vm.legalPhone),callback:function ($$v) {_vm.legalPhone=$$v},expression:"legalPhone"}}),_c('van-field',{attrs:{"name":"companyName","label":"公司名称","placeholder":"公司名称（可填）","rules":[{ required: false, message: '公司名称不能为空' }]},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}}),(_vm.smscode==true)?_c('van-field',{attrs:{"name":"iptImgCode","placeholder":"请输入图中字符（必填）","rules":[{ required: _vm.smscode, message: '请输入图中字符（必填）' }]},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('div',{staticClass:"imgButton",on:{"click":_vm.getImg64}},[_c('img',{attrs:{"src":_vm.img64,"alt":""}})])]},proxy:true}],null,false,4278394800),model:{value:(_vm.iptImgCode),callback:function ($$v) {_vm.iptImgCode=$$v},expression:"iptImgCode"}}):_vm._e(),(_vm.smscode==true)?_c('van-field',{attrs:{"name":"msgCode","placeholder":"短信验证码（必填）","rules":[{ required: _vm.smscode, message: '请输入验证码' }]},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('div',{staticClass:"button"},[(_vm.time == 0)?_c('span',{on:{"click":_vm.getCode}},[_vm._v("获取验证码")]):_c('van-count-down',{attrs:{"time":_vm.time},on:{"finish":_vm.timeOut},scopedSlots:_vm._u([{key:"default",fn:function(timeData){return [_c('span',{staticClass:"block",on:{"click":_vm.dontAgain}},[_vm._v(_vm._s(timeData.seconds)+"秒")])]}}],null,false,843959907)})],1)]},proxy:true}],null,false,2283883685),model:{value:(_vm.msgCode),callback:function ($$v) {_vm.msgCode=$$v},expression:"msgCode"}}):_vm._e(),_c('div',{staticStyle:{"margin":"16px"}},[_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit","color":"#f76518"}},[_vm._v(" 提交申请 ")])],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }