<template>
  <div>
    <div class="titleBox">
      <div class="logoBox">
        <img
          src="//image.jiazhenglaoban.com/static/app/app_icon_xianlaoshi.png"
          alt="Logo"
        />
        <h1></h1>
        <h2>开家政公司，用贤老师家政系统！</h2>
      </div>
      <!-- <div class="container"> -->
      <!-- <p style="text-indent: 2em;">
                管事宝是广州互缘网络科技有限公司旗下的一款专注于面向家政公司的SaaS管理工具，以互联网技术为优势，以整合家政资源为特色，为家政公司提供全方位的赋能，助力家政行业的互联网+升级！
                面向家政行业，我们支持家政中介，保洁，产后康复，母婴月嫂等类型的门店，同时也不断完善产品，以服务更多家政业态。</p> -->
      <!-- <p>开家政公司，用管事宝系统！</p>
        </div> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
* {
  list-style: none;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  // background-color: #ffffff;
  img {
    display: block;
    width: 100%;
  }
}
.titleBox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  align-content: center;
  //background: url("https://fr-static.jiazhengye.cn/home_banner.d7a0d6d3ee9bc9b2.png")
  //  no-repeat center right/cover;
  background-color: #e40011;
  color: white;

  .logoBox {
    display: flex;
    text-align: right;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    h1 {
      font-size: 20px;
      display: inline-block;
      margin: 0;
      margin: 0 5px;
    }
    img {
      width: 6vw;
      height: 6vw;
      max-width: 40px;
      max-height: 40px;
      /* transform: translateX(-60%); */
    }
    h2 {
      font-size: 22px;
    }
  }

  .container {
    /* padding: 20px; */
    max-width: 550px;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    line-height: 1.4;
    p {
      margin: 2% 0;
    }
  }
}
</style>
