import http from "../utils/http.js"

// 获取图形验证码
export async function getImageCode(string) {
    return http({
        url: `/platform/client/captcha/image?ts=${string}`,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        responseType: 'arraybuffer'
    })
}


// 获取短信验证码
export async function getPhoneCode(data) {
    return http({
        method: "POST",
        url: "/platform/client/register/code",
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}


// 提交注册
export async function submitSign(data) {
    return http({
        method: "POST",
        url: "/platform/client/register/submit",
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}


// 上传图片资料，返回字符串
export async function upLoadImg(file) {
    return http({
        method: "POST",
        url: "/platform/file/upload",
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: {
            files: file
        }
    })
}