export default {
    namespaced: true,
    state: {
        addResumeData: {},//add页面收集的简历信息以及uploadid页面上传身份证的信息
        isCreate: false,//是否已经创建过简历
        secondFields: [],//add页面请求回来的，用于PersonalPage页渲染的数组数据，数据的type值：{
        //     0:单选,
        //     1:多选,
        //     2:输入框，
        //     3:只能数字（跟2一样输入框）,
        //     4:多行文本,
        //     10:弹出框
        // }
        servData:{},//注册过简历，才有的信息
    },
    getters: {},
    mutations: {
        changeAddResData(state, res) {
            state.addResumeData = res
        },
        changeSecondF(state, res) {
            // localStorage.setItem("secondFields",JSON.stringify(res))
            state.secondFields = res
        },
        changeIsCreated(state, res) {
            state.isCreate = res
        },
        changeServData(state, res) {
            // localStorage.setItem("service",JSON.stringify(res))
            state.servData = res
        },
    },
    actions: {},
}