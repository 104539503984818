<template>
<div class="maxWidthWrap">
    <div class="leftLayout"></div>
    <div class="midLayout">
        <slot></slot>
    </div>
    <div class="rightLayout"></div>
</div>
</template>

<script>
export default {
    name:"maxWidthWrapfrom"
}
</script>

<style lang="scss" scoped>
.maxWidthWrap{
    display: flex;
    justify-content: center;
    .midLayout{
        width: 100%;
        max-width: 550px;
        position: relative;
    }
    .leftLayout{
        flex: 1;
    }
    .rightLayout{
        flex: 1;
    }
}

</style>