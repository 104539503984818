export default {
    namespaced: true,
    state: {
        signForm: {},//免费注册收集的表单信息
    },
    getters: {},
    mutations: {
        changeSignForm(state, res) {
            state.signForm = res
        }
    },
    actions: {},
}