import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    // component: () => import("../views/CompanyHome/Index.vue"),
    meta: {
      title: "贤老师家政系统 - 家佑（广州）科技有限公司"
    }
  },
  {
    path: '/newHome',
    name: "newHome",
    component: () => import("../views/newHome/newHome.vue"),
    meta: {
      title: "贤老师家政系统"
    }
  },
  {
    path: "/SignIn",
    name: "SignIn",
    component: () => import("../views/SignIn/Index.vue")
  },
  {
    path: '/Details',
    name: 'Details',
    component: () => import("../views/Details/Index.vue"),
    meta: {
      title: " "
    }
  },
  {
    path: "/ClientMsg",
    name: "ClientMsg",
    component: () => import("../views/ClientMsg/clientMsg.vue")
  },
  {
    path: "/Company",
    name: "Company",
    meta: {
      title: " "
    },
    component: () => import("../views/Company/Index.vue"),
    redirect: "/CompanyHome",
    children: [
      {
        path: "/CompanyHome",
        name: "CompanyHome",
        component: () => import("../views/CompanyHome/Index.vue"),
        meta: {
          title: " "
        }
      },
      {
        path: "/FindAunt",
        name: "FindAunt",
        component: () => import("../views/FindAunt/findAunt.vue"),
        meta: {
          title: " "
        }
      },
      {
        path: "/AboutUs",
        name: "AboutUs",
        component: () => import("../views/AboutUs/aboutUs.vue"),
        meta: {
          title: " "
        }
      },
    ]
  },
  {
    path: "/News",
    name: "News",
    component: () => import("../views/News/Index.vue"),
    meta: {
      title: " "
    }
  },
  {
    path: "/Resume",
    name: "Resume",
    component: () => import("../views/Resume/Index.vue"),
    redirect: "/Resume/Add",
    meta: {
      title: "家政员加入"
    },
    children: [
      {
        path: "Add",
        name: "Add",
        component: () => import("../views/Resume/children/Add.vue")
      },
      {
        path: "UpLoadId",
        name: "UpLoadId",
        component: () => import("../views/Resume/children/upLoadId.vue")
      },
      {
        path: "upLoadHeadPic",
        name: "upLoadHeadPic",
        component: () => import("../views/Resume/children/upLoadHeadPic.vue")
      },
      {
        path: "upLoadPaper",
        name: "upLoadPaper",
        component: () => import("../views/Resume/children/upLoadPaper.vue")
      },
      {
        path: "PersonalPage",
        name: "PersonalPage",
        component: () => import("../views/Resume/children/personalPage.vue")
      },
      {
        path: "WorkHistory",
        name: "WorkHistory",
        component: () => import("../views/Resume/children/workHistory.vue")
      },
      {
        path: "WorkAdd",
        name: "WorkAdd",
        component: () => import("../views/Resume/children/workAdd.vue")
      }
    ]
  },
  {
    path: "/404",
    name: "NotFound",
    component: () => import("../views/404/Index.vue")
  },
  {
    path: "*",
    redirect: "/404"
  }
]

const router = new VueRouter({
  routes
})



export default router
