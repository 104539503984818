import longTap from "./directives/longTap"

// 自定义指令
const directives = {
    longTap
}

// 假如有多个指令的话，直接引入，这里会做遍历，不用在main.js中多个引入
export default {
    install(Vue) {
        Object.keys(directives).forEach((key) => {
            Vue.directive(key, directives[key])
        })
    },
}