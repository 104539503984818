<template>
<div class="wrap">
    <h3>公司简介</h3>
    <p style="text-indent: 2em;">
        贤老师家政系统是家佑（广州）科技有限公司旗下的一款专注于面向家政公司的SaaS管理工具，以互联网技术为优势，以整合家政资源为特色，为家政公司提供全方位的赋能，助力家政行业的互联网+升级！
        面向家政行业，我们支持家政中介，保洁，产后康复，母婴月嫂等类型的门店，同时也不断完善产品，以服务更多家政业态。
    </p>

    <div id="copy_right_show" class="copy_right">Copyright &copy; 2014-2022 All Rights Reserved.</div>
    <div class="other_info">
        <a id="beianhao" href="http://beian.miit.gov.cn/publish/query/indexFirst.action"
            target="_blank">粤ICP备2023035448号</a>
    </div>
    <div class="copy_right" id="beianname">版权所有：家佑（广州）科技有限公司</div>

</div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
* {
    list-style: none;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    // background-color: #ffffff;
    img{
      display: block;
      width: 100%;
    }
}
.wrap{

    /* box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.2); */
    text-align: center;
    line-height: 2.0;
    /* padding: 20px 0; */
    padding-bottom: 20%;

    p {
        padding: 0 3% 3%;
        text-align: left;
    }
    h3{
        // text-align: left;
        margin: 4% 0;
    }
    .download a {
    display: block;
    width: 100%;
    height: 100%;
}
}








</style>
