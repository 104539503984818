<template>
<div class="wrap">
    <div class="item" v-for="(item,i) in productList" :key="i">
        <div :class="{'top':true,'moveLeft':widthMaths}">
            <div :class="{'imgBox':true,'smallSizeImgBox':widthMaths}">
                <img :src="item.logoPath" alt="">
            </div>
            <h2>{{ item.name }}</h2>
        </div>
        <p v-for="(ele,i) in item.content" :key="i">{{ ele }}</p>
    </div>
</div>
</template>

<script>
// 图标引入
import icon_services from "../../../assets/productLogo/icon_services.png"
import Invite from "../../../assets/productLogo/Invite.png"
import icon_shop from "../../../assets/productLogo/icon_shop.png"
import icon_contract from "../../../assets/productLogo/icon_contract.png"
import icon_card_manager from "../../../assets/productLogo/icon_card_manager.png"
import kehuguanli from "../../../assets/productLogo/kehuguanli.png"

export default {
    mounted() {
        this.getWindowWidth()//获取屏幕宽度
    },
    data() {
        return {
            productList: [
                {
                    name: "视频面试",
                    logoPath: icon_services,
                    content:["随时随地发起视频面试，极速面试提升签单效率，避免签单周期太久被同行提前签约。"]
                },
                {
                    name: "阿姨招聘",
                    logoPath: Invite,
                    content:["一键发布招聘，阿姨简历详细丰富，快速匹配优质阿姨！"]
                },
                {
                    name: "阿姨管理",
                    logoPath: icon_shop,
                    content:["手机在线录入阿姨简历，在线预览，还能一键分享给雇主或阿姨。让家政老师操作更简单，效率更高！","支持生成热文推广，一键拼单找阿姨等，让家政老师找阿姨更轻松。"]
                },
                {
                    name: "电子合同",
                    logoPath: icon_contract,
                    content:["支持添加合同，查看到期合同等功能。让家政老师随时随地签合同。"]
                },
                {
                    name: "家政保险",
                    logoPath: icon_card_manager,
                    content:["支持家政老板快速给客户与阿姨购买保险。","在线查询身份证真伪，让家政老师谈单更方便，签单更容易！"]
                },
                {
                    name: "客户管理",
                    logoPath: kehuguanli,
                    content:["在线管理客户，随时录入客户跟进记录，以及客户资料。"]
                }
            ],
            screenWidth: null,//屏幕宽度
        }
    },
    computed: {
        /* 
        监听屏幕宽度变化，当数据变化大于750时候，改变样式
         */
        widthMaths() {
            if (this.screenWidth >= 750) {
                return true
            } else {
                return false
            }
        }
    },
    methods: {
        /* 
        获取屏幕宽度
        */
        getWindowWidth() {
            this.screenWidth = document.body.clientWidth
            window.onresize = () => {
                //屏幕尺寸变化
                return (() => {
                    this.screenWidth = document.body.clientWidth
                    console.log(this.screenWidth);
                })()
            }
        },


    }
}
</script>

<style lang="scss" scoped>
* {
    list-style: none;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    // background-color: #ffffff;
    img{
      display: block;
      width: 100%;
    }
}
.wrap{
    .item {
        padding: 1vh;
        padding-bottom: 2vh;
        line-height: 2.0;
        .top {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 95%;
            margin: 0 auto;
            h2 {
                padding-left: 3%;
                vertical-align: middle;
                padding: 1vh;
                line-height: 2.0;
            }
            .imgBox {
                /* width: 8%; */
                width: 7vw;
            }
            .smallSizeImgBox {
                width: 4vw;
                padding-left: 2em;
            }
        }
        p {
            /* width: 95%; */
            margin: 0 auto;
            color: black;
            padding: 1vh 2%;
            text-indent: 2em;
        }
        .moveLeft {
            justify-content: start;
        }
    }
    .item:nth-child(even) {
        background-color: #f4f4f4;
    }
}


main .item .top {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    margin: 0 auto;
}

main .item .top h2 {
    padding-left: 3%;
    vertical-align: middle;
}

main .item .top .imgBox {
    /* width: 8%; */
    width: 7vw;
}

main .item .top .smallSizeImgBox {
    width: 4vw;
    padding-left: 2em;
}

/* main .item h2 {
    text-align: center;
} */

main .item p {
    /* width: 95%; */
    margin: 0 auto;
    color: black;
    padding: 1vh 2%;
    text-indent: 2em;
}

main .item .imgBox img {
    width: 100%;
    display: block;
}

main .item:nth-child(even) {
    background-color: #f4f4f4;
}
</style>