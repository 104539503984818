import store from "../store/index.js"
import axios from "axios"
import { Toast } from 'vant'
/*
    vant的开始加载动画
*/
function startLoading() {
    Toast.loading({
        message: '加载中...',
        forbidClick: true,
    });
}
/*
    结束加载动画
*/
function endLoading() {
    // 结束加载
    Toast.clear()
}

const env = process.env.NODE_ENV || "prod"
let baseURL;
if (env == "development") {
    baseURL = "/api"
} else {
    baseURL = "https://api.jiazhenglaoban.com/"//生产
}

baseURL = "https://api.jiazhenglaoban.com/"//生产


let http = axios.create({
    baseURL: baseURL,

    // 设置请求头格式
    headers: {
        'Content-Type': "application/x-www-form-urlencoded",
        // 'Content-Type': "multipart/form-data"
    },
})


http.interceptors.request.use((config) => {
    startLoading();
    // 后台说query值只要有就携带给回后台
    let q = JSON.parse(localStorage.getItem("queryAll"))
    let d = {
        ...config.data
    }
    let userId =localStorage.getItem("userId")
    // console.log(config);
    if (JSON.stringify(q) != "{}") {
        try {
            d['muuid'] = q['muuid'];
        } catch (e) {
        }
        try {
            d['cid'] = q['cid'];
        } catch (e) {
        }
        try {
            d['mdid'] = q['mdid'];
        } catch (e) {
        }
        try {
            d['fingerprintID'] = q['fingerprintID']
        } catch (e) {
        }
        try {
            d['mdservid'] = q['mdservid']
        } catch (e) {
        }
        try {
            d['userId'] = userId
        } catch (e) {
        }
        // if (config.data) {
        //     q = {
        //         ...q,
        //         ...config.data
        //     }
        // }
        // config.data = q;
    }
    config.data = d;
    // console.log(config);
    // d['muuid'] = q['muuid']
    // d['mdid'] = q['mdid']
    // d['cid'] = q['cid']
    // if (config.data) {
    //     d.addAll(config.data)
    // }
    return config
})

// 响应拦截器
http.interceptors.response.use((response) => {
    endLoading()
    const { code, message } = response.data
    if (code === 200) {
        return response
    } else if (code === 500) {
        return Promise.reject(message)
    } else if (code === -1) {
        return Promise.reject(message)
    }

    return response
})

export default http
