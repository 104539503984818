<template>
  <div class="imgList">
    <ul>
      <li v-for="(item, i) in imgList" :key="i">
        <img :src="item" alt="" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgList: [
        // require("../../../assets/home/1.png"),
        // require("../../../assets/home/2.png"),
        // require("../../../assets/home/3.png"),
        // require("../../../assets/home/4.png"),
        // require("../../../assets/home/5.png"),
        require("../../../assets/home/16.png"),
        require("../../../assets/home/17.png"),
        require("../../../assets/home/18.png"),
        require("../../../assets/home/19.png"),
        require("../../../assets/home/110.png"),
        require("../../../assets/home/111.png"),
          require("../../../assets/home/112.png"),
          require("../../../assets/home/113.png"),
          require("../../../assets/home/114.png"),
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.imgList {
  width: 100%;
  ul {
    li {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
      }
    }
  }
}
</style>
