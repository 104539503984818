<template>
  <div class="popupForm">
    <van-popup
      v-model="popupShow"
      :close-on-click-overlay="false"
      round
      :style="{ width: '90%', maxWidth: '495px' }"
      @click-close-icon="close"
      closeable
      close-icon="close"
    >
      <h2>填写联系方式</h2>
      <div class="form">
        <van-form @submit="onSubmit">

          <!-- <van-field
            v-model="legalPerson"
            name="legalPerson"
            label="联系人姓名"
            placeholder="联系人姓名（必填）"
            :rules="[{ required: true, message: '请输入正确的姓名' }]"
          /> -->

          <van-field
            v-model="legalPhone"
            name="legalPhone"
            label="联系人手机号"
            placeholder="联系人手机号（必填）"
            @input="getPhone"
            :rules="[
              {
                required: true,
                message: '请输入正确的手机号',
                validator: checkPhone,
              },
            ]"
          />


          <van-field
                  v-model="companyName"
                  name="companyName"
                  label="公司名称"
                  placeholder="公司名称（可填）"
                  :rules="[{ required: false, message: '公司名称不能为空' }]"
          />

         <van-field
            v-model="iptImgCode"
            v-if="smscode==true"
            name="iptImgCode"
            placeholder="请输入图中字符（必填）"
            :rules="[{ required: smscode, message: '请输入图中字符（必填）' }]"
          >
            <template #button>
              <div class="imgButton" @click="getImg64">
                <img :src="img64" alt="" />
              </div>
            </template>
          </van-field>

          <van-field
            v-model="msgCode"
            v-if="smscode==true"
            name="msgCode"
            placeholder="短信验证码（必填）"
            :rules="[{ required: smscode, message: '请输入验证码' }]"
          >
            <template #button>
              <div class="button">
                <span @click="getCode" v-if="time == 0">获取验证码</span>
                <van-count-down :time="time" v-else @finish="timeOut">
                  <template #default="timeData">
                    <span class="block" @click="dontAgain"
                      >{{ timeData.seconds }}秒</span
                    >
                  </template>
                </van-count-down>
              </div>
            </template>
          </van-field>

          <div style="margin: 16px">
            <van-button
              round
              block
              type="info"
              native-type="submit"
              color="#f76518"
            >
              提交申请
            </van-button>
          </div>
        </van-form>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getImageCode, getPhoneCode, submitSign } from "@/api/homeAndSign.js";
import { mapMutations, mapState } from "vuex";
import { Toast } from "vant";
export default {
  created() {
    this.getImg64();
  },
  data() {
    return {
      popupShow: false, //关闭开关
      companyName: "", //公司名字
      legalPerson: "", //法人、联系人姓名
      legalPhone: "", //用户手机号码
      msgCode: "", //用户接收的短信验证码，是由电话号码以及
      time: 0, //倒计时时间
      img64: require("@/assets/productLogo/fail.png"), //图形验证码的图片（转换后的）
      ts: "", //随机生成的参数，用来一开始请求图形验证码
      iptImgCode: "", //用户输入对应的图形验证码
    };
  },
  methods: {
    ...mapMutations("signIn", ["changeSignForm"]),
    // 随机生成字符串，获取图形验证码，转64
    async getImg64() {
      var rand = Math.floor(Math.random() * 999);
      for (var i = 0; i < 3; i++) {
        var r = Math.floor(Math.random() * 10);
        rand += r;
      }
      this.ts = rand;

      let img = await getImageCode(rand)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          Toast.fail(err);
        });
      if (!img) return;
      await this.getBase64(img).then((res) => {
        this.img64 = res;
      });
    },
    // 二进制转base64
    async getBase64(data) {
      return new Promise((resolve, reject) => {
        const blob = new Blob([data], { type: "image/jpg" }); // 必须指定type类型
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // 不能重复点击
    dontAgain() {
      Toast.fail("请勿重复发送验证码");
    },
    // 倒计时结束
    timeOut() {
      this.time = 0;
    },
    // 检验手机号正则
    checkPhone(val) {
      return /^1[3456789]\d{9}$/.test(val);
    },
    // 表单提交按钮，成功后会跳转页面
    async onSubmit(values) {
      // console.log(values); //可以拿到整个表单对象，不过不用他的，单纯帮我们拦截
      // console.log(this.signData);
      await submitSign(this.signData)
        .then((res) => {
          console.log(res);
          const { code } = res.data;
          if (code == 200) {
            this.$emit("closePopup", false);
            Toast.success("提交成功~");
            localStorage.setItem("userId", res.data.data)

            if(this.savePhone){
            }else{
                this.$router.push({
                    path: "/signIn",
                    query:this.$route.query
                });
            }

          }
        })
        .catch((err) => {
          Toast.fail(err);
        });
    },
    // 获取验证码
    async getCode() {
      if (!this.iptImgCode) {
        Toast.fail("请先输入图形验证码");
        return;
      }
      if (this.checkPhone(this.legalPhone)) {
        Toast.allowMultiple();

        await getPhoneCode(this.msgData)
          .then((res) => {
            Toast.clear();
            Toast.loading("验证码发送中...");
            this.time = 30 * 1000; //倒计时时间多久
            // console.log(res);
          })
          .catch((err) => {
            Toast.clear();
            Toast.fail(err);
          });
      } else {
        Toast.fail("请输入正确的手机号");
      }
    },
    // 关闭弹窗
    close() {
      this.$emit("closePopup", false);
    },
    // 用户输入手机号触发，限制最多11位
    getPhone(e) {
      this.legalPhone=e.slice(0,11)
    },
  },
  computed: {
    ...mapState("signIn", ["signForm"]),
    // 短信验证码的表单
    msgData() {
      let obj = {
        ts: this.ts,
        code: this.iptImgCode,
        legalPhone: this.legalPhone,
      };
      return obj;
    },
    // 提交申请需要的表单
    signData() {
      let obj = {
        ...this.$route.query,
        ts: this.ts,
        code: this.msgCode,
        legalPerson: this.legalPerson,
        legalPhone: this.legalPhone,
        companyName: this.companyName,
      };
      if(this.regFrom){
          obj.regFrom = this.regFrom;
      }
      this.changeSignForm(obj);
      return obj;
    },
  },
  props: {
    // v-model不能直接绑定show，即使后续是通过this.$emit("closePopup", false);来改变show的值，但是因为本页面v-model绑定的是show，导致实际上相当于绑定了一个名为 show 的 prop 和一个名为 update:show 的事件。所以，当你在子组件中通过 $emit 触发 closePopup 事件时，会导致直接修改父组件传递的 show prop 值，从而引发警告。只能隔开一个值
    show: {
      type: Boolean,
      default: false,
    },
      smscode: {
          type: Boolean,
          default: false,
      },
      savePhone: {
          type: Boolean,
          default: false,
      },
      regFrom: {
          type: String,
      },
  },
  watch: {
    show: {
      handler(newValue) {
        this.popupShow = newValue;
      },
    },smscode: {
          handler(newValue) {
              this.smscode = newValue;
          },
      },
  },
};
</script>

<style lang="scss" scoped>
.popupForm {
  width: 100%;
  h2 {
    margin: 16px;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
  }

  .button {
    color: blue;
    .block {
      display: inline-block;
      width: 50px;
    }
  }
  .imgButton {
    width: 100px;
    max-height: 45px;
    img {
      object-fit: contain;
    }
  }
}
</style>
