<template>
  <div class="app">
    <router-view v-wechat-title="$route.meta.title"></router-view>
  </div>
</template>

<script>
document.documentElement.style.fontSize =
  (document.documentElement.clientWidth / 750) * 100 + "px";
// 》》对应的宽度单位改成7.5rem就等于100%屏幕宽度，手机端的设置
window.onload = function () {
  document.addEventListener("touchstart", function (e) {
    console.log("1", e.touches.length);
    if (e.touches.length > 1) {
      e.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (e) {
    console.log("2");
    e.preventDefault();
  });
};
</script>
<script>
export default {};
</script>


<style lang="scss">
* {
  list-style: none;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  font-size: 16px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.app {
  min-width: 250px;
}
</style>
