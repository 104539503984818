import Vue from 'vue'
import Vuex from 'vuex'
import resume from "./resume.js"//创建简历页面需要用到的仓库
import signIn from "./signIn.js"//首页以及去注册页面用到的仓库
Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    muuid: "",//company请求的uuid
    openIDFlag: false,//全局只会开启一次，在进入提交服务页面关闭
    queryAll: {},//首次进入companyhome时候获取的全部query值
    mdDetails: {},//请求回来的门店情况，包括公司名字，联系电话，头像，门店地址
    userDetails: {},//请求回来user的数据
    wrapBoxShow: false,//首页控制蒙层弹出的开关，以及signin页面会用到，当安卓且微信浏览器打开时候触发
  },
  getters: {
  },
  mutations: {
    changeMuuid(state, res) {//存muuid
      state.muuid = res;
    },
    closeFlag(state, res) {//关闭提交openid接口
      sessionStorage.setItem("openIDFlag", res)
      state.openIDFlag = res
    },
    changeQueryAll(state, res) {//获取url的全部query值给到仓库，然后放在缓存里面，之后http.js从缓存里面拿出来，请求时候携带到data里面就好了
      // console.log(res);
      state.queryAll = res
      localStorage.setItem("queryAll", JSON.stringify(res))
    },
    changeMd(state, res) {
      // 门店信息缓存下来，不然去留言页面数据会丢失，之后再在ClientMsg的销毁阶段清除md缓存
      state.mdDetails = res;
      localStorage.setItem("md", JSON.stringify(res))
    },
    changeUser(state, res) {
      state.userDetails = res
    },
    changeWrapShow(state, res) {
      state.wrapBoxShow = res
    }
  },
  actions: {
  },
  modules: {
    resume,
    signIn
  }
})
