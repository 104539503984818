<template>
  <max-width-wrap>
    <div class="home">
      <!-- 全屏蒙层 -->
      <div
        class="indexWrap"
        v-show="wrapBoxShow"
        @click="changeWrapShow(false)"
      ></div>

      <!-- 下载右上角提示 -->
      <div
        class="downGuide"
        v-show="wrapBoxShow"
        @click="changeWrapShow(false)"
      >
        <ul>
          <li>
            <i class="iconfont icon-fenxiangzhiyin"></i>
          </li>
          <li>1、点击右上角的...</li>
          <li>2、选择在浏览器中打开</li>
          <li>3、点击下载或安装即可</li>
        </ul>
      </div>

      <!-- 顶部logo和背景图 -->
      <banner></banner>

      <!-- <button @click="test">点击请求</button> -->

      <!-- 中部产品展示 -->
      <!-- <product-show></product-show> -->

      <!-- 改为图片介绍 -->
      <img-introduceVue></img-introduceVue>

      <!-- 弹出表单 -->
      <popup-form :show="show" @closePopup="show = false"></popup-form>

      <!-- 底部公司简介 -->
      <base-footer></base-footer>

      <!-- 底部按钮咨询和下载 -->
      <footer>
        <div class="footerWrap">
          <!-- <button class="phone" @click="callPhone">咨询:020-33279580</button>-->
          <button @click="download" class="download" v-show="showDownload">下载App</button>
          <button @click="completeData">申请注册</button>
        </div>
      </footer>
    </div>
  </max-width-wrap>
</template>



<script>
import Banner from "./component/Banner.vue";
import BaseFooter from "./component/BaseFooter.vue";
import imgIntroduceVue from "./component/imgIntroduce.vue";
import ProductShow from "./component/ProductShow.vue";
import popupForm from "./component/popupForm.vue";
import { mapMutations, mapState } from "vuex";
import { Toast } from "vant";
// import logo from "@/assets/logo.png";
export default {
  name: "Home",
  created() {
    // let shareObj = {//自定义分享
    //   title: "测试2", // 分享标题
    //   link: "https://api.jiazhenglaoban.com/", // 分享链接
    //   desc: "描述2", // 分享描述
    //   imgUrl: "https://img01.yzcdn.cn/upload_files/2022/11/16/FhUO8D256yhFzsTiVodxIDMc4-hN.jpg?imageView2/2/w/260/h/260/q/75/format/jpg",
    // };
    // this.$wechat.wxShare(shareObj);
      try {
          if (window.__wxjs_environment === 'miniprogram') {
              this.showDownload = false;
          }
      } catch (e) {
      }
  },
  data() {
    return {
      show: false, //控制弹出表单开关
        showDownload: true, //非小程序都显示
    };
  },
  methods: {
    ...mapMutations("signIn", ["changeSignForm"]),
    ...mapMutations(["changeWrapShow"]),
    /*
    下载APP按钮
    */
    download() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        // window.location = "https://itunes.apple.com/us/app/example-app/id6468899789?mt=8";
        window.location.href = "https://apps.apple.com/app/id6482990478"; //https://apps.apple.com/app/id6468899789也可以
      } else {
        // 判断是不是微信打开浏览器的情况
        var ua = navigator.userAgent;
        var isWeixin = !!/MicroMessenger/i.test(ua);
        if (isWeixin) {
          this.changeWrapShow(true); //打开蒙层
            // window.location =
            //     "jiazhenglaoban://app.jiazhenglaoban.com/apk/app-release.apk";
        } else {
          window.location =
            "https://image.jiazhenglaoban.com/apk/app-release.apk";
        }
      }
    },

    /*
    拉起电话呼叫功能
    */
    callPhone() {
      window.location.href = "tel:" + "020-33279580";
    },
    // 完善资料，弹出表单
    completeData() {
      this.show = true;
      let obj = {
        ...this.signForm,
        ...this.$route.query,
      };
      this.changeSignForm(obj);
    },
  },
  computed: {
    ...mapState("signIn", ["signForm"]),
    ...mapState(["wrapBoxShow"]),
  },
  components: {
    Banner,
    BaseFooter,
    ProductShow,
    imgIntroduceVue,
    popupForm,
  },
};
</script>

<style lang="scss" scoped>
* {
  list-style: none;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  // background-color: #ffffff;
  img {
    display: block;
    width: 100%;
  }
}
.home {
  .indexWrap {
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 2;
    position: fixed;
    opacity: 0.8;
  }

  .downGuide {
    position: fixed;
    right: 0;
    z-index: 2;
    color: white;
    font-weight: 700;
    font-size: 5vw;
    width: 90%;
  }

  .downGuide ul li:first-child {
    text-align: right;
  }

  .downGuide ul li i {
    font-size: 10vw;
  }

  .downGuide ul li {
    padding: 2% 0;
  }

  footer {
    // position: relative;
    .footerWrap {
      display: flex;
      /* margin-top: 20px; */
      align-items: center;
      justify-content: space-around;
      width: 100%;
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: #e40011;
      max-width: 550px;

      button {
        font-size: 20px;
        line-height: 1.2;
        color: white;
        padding: 15px 0;
        //border-radius: 6px;
        text-decoration: none;
        transition: background-color 0.2s ease-in-out;
        background-color: transparent;
        width: 100%;
        border-radius: 10px;
        border: none;
        flex: 2;
        font-weight: 500;
      }
      .download {
        flex: 1;
        border-right: 1px solid white;
        border-radius: 0;
      }
    }
  }
}
</style>
