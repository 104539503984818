/* 
    长按事件v-longTap="具体函数"
    默认时间3秒，页面使用示例
    <div v-longTap="handler">，如果想改长按时间就改为<div v-longTap:[1000]="handler">
*/
const longTap = {
    bind(el, binding) {
        const cb = binding.value;
        el.$duration = binding.arg || 3000; // 获取长按时长, 默认3秒执行长按事件
        if (typeof (cb) !== 'function') return console.warn('v-longpress指令必须接收一个回调函数');
        let timer = null;
        const add = (e) => {
            console.log(e);
            // 排除点击与右键情况, event.button: 0-左键  2-右键
            // if (e.type === 'click' && e.button !== 0) return;
            // e.preventDefault();
            
            if (timer === null) {
                timer = setTimeout(() => {
                    cb();
                    timer = null;
                }, el.$duration)
            }
        }
        const cancel = (e) => {
            if (timer !== null) {
                console.log(e.type);
                clearTimeout(timer);
                timer = null;
            }
        }

        // 添加计时器
        el.addEventListener('mousedown', add);
        el.addEventListener('touchstart', add, { passive: true });
        // 取消计时器
        el.addEventListener('click', cancel);
        el.addEventListener('mouseout', cancel);
        el.addEventListener('touchmove', cancel, { passive: true });
        el.addEventListener('touchend', cancel)
        // el.addEventListener('touchcancel', cancel)
    },
    componentUpdated(el, binding) {
        // 可以实时更新时长
        el.$duration = binding.arg;
    },
    unbind(el) {
        el.removeEventListener('mousedown', () => { });
        el.removeEventListener('touchstart', () => { });
        el.removeEventListener('click', () => { });
        el.removeEventListener('mouseout', () => { });
        el.removeEventListener('touchend', () => { });
        // el.removeEventListener('touchcancel', () => { });
        el.removeEventListener('touchmove', () => { });
    }
}
export default longTap