
import "babel-polyfill"

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/*
动态标题
使用介绍：https://blog.csdn.net/yuan_jlj/article/details/108864131
*/
import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)

/*
全部Vant组件导入
文档：https://vant-contrib.gitee.io/vant/v2/#/zh-CN/
*/
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import "./assets/productLogo/font_4111099_35eu49vkv8k/iconfont.css"//本地logo

import maxWidthWrapfrom from "./components/maxWidthWrap/index.vue"//中部盒子最大宽度550px
Vue.component("max-width-wrap", maxWidthWrapfrom);


import Directives from "@/utils/directives.js"
Vue.use(Directives);//自定义指令

import "./css/animate.css"//动画库：文档https://animate.style/，搭配WOW一起使用https://wowjs.uk/

// wxSDK自定义分享
// import wechat from '@/utils/share.js'
// Vue.prototype.$wechat = wechat



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
